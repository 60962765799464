import restClient from "./api";
import NProgress from "nprogress";
import { mustGetEnv } from "@/utils/env";

let baseURLFileService = mustGetEnv("VUE_APP_FILESERVICE_BASEURL");

class FileService {
  /*
    file - An actual object of type File
    json  -  {
              "user_ids":["1"], (optional)
              "roles":["1"]     (optional)
            }
  */
  uploadFile(file, json, startProgress = 0, maxContribution = 1) {
    const formData = new FormData();
    formData.append("upload_files", file);
    formData.append("data", JSON.stringify(json));

    return restClient.post(
      baseURLFileService + "/upload-file",
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      {
        onUploadProgress: function (
          startProgress,
          maxContribution,
          progressEvent
        ) {
          var percentage =
            startProgress +
            (progressEvent.loaded / progressEvent.total) * maxContribution;
          console.log(percentage);
          if (percentage > 0.95) {
            NProgress.done();
          } else {
            NProgress.set(percentage);
          }
        }.bind(this, startProgress, maxContribution),
      }
    );
  }

  listFileById(fileId) {
    return restClient.get(
      baseURLFileService + "/list-file-by-id?id=" + fileId,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }

  getFileById(fileId) {
    return restClient.get(
      baseURLFileService + "/files/" + fileId,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      },
      {
        onDownloadProgress: function (progressEvent) {
          var percentage = progressEvent.loaded / progressEvent.total;
          if (percentage > 0.9) {
            NProgress.done();
          } else {
            NProgress.set(percentage);
          }
        }.bind(this),
      }
    );
  }

  getResultFileById(fileId) {
    return restClient.get(
      baseURLFileService + "/files/" + fileId,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "arraybuffer",
      },
      {
        onDownloadProgress: function (progressEvent) {
          var percentage = progressEvent.loaded / progressEvent.total;
          if (percentage > 0.9) {
            NProgress.done();
          } else {
            NProgress.set(percentage);
          }
        }.bind(this),
      }
    );
  }

  getResultById(fileId) {
    return restClient.get(
      baseURLFileService + "/files/" + fileId,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob", // Set response type to blob
      },
      {
        onDownloadProgress: function (progressEvent) {
          var percentage = progressEvent.loaded / progressEvent.total;
          if (percentage > 0.9) {
            NProgress.done();
          } else {
            NProgress.set(percentage);
          }
        }.bind(this),
      }
    );
  }

  deleteFileById(fileId) {
    return restClient.post(
      baseURLFileService + "/delete-file",
      { id: fileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }

  /*
    {
      "id":"124",
      "directory_id":"102",
      "add_user_ids":["12"],
      "add_roles":["lawyer"],
      "remove_user_ids":["888"],
      "remove_roles":["customer"]
    }
  */
  updateFile(json) {
    return restClient.post(baseURLFileService + "/update-file", json, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  forceFileDownload(response, title) {
    console.log(title);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
  }

  downloadWithAxios(fileId, title) {
    this.listFileById(fileId).then(
      (response) => {
        if (response.data != null) {
          this.getFileById(fileId).then((response2) => {
            let fileName = response.data.name || response.data.originalFileName;
            if (fileName.includes(".ply")) {
              fileName = fileName.replace(".ply", ".stl");
            } else if (fileName.includes(".obj")) {
              fileName = fileName.replace(".obj", ".stl");
            }
            this.forceFileDownload(response2, fileName);
          });
        }
      },
      (error) => {
        NotificationService.showError(error);
      }
    );
  }

  downloadFiles(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i] == null || array[i] == undefined) {
        continue;
      }

      this.downloadWithAxios(array[i]);
    }
  }
}

export default new FileService();
